<template>

  <v-sheet
      :color="backgroundColor ? backgroundColor : '#000000'"
      :dark="!light"
      :height="height"
      :width="width"
      class="wsRoundedLight d-flex align-center justify-center fill-height "
      >

    <iframe
        v-if="isValid"
        :src="EMBED_URL"
        :style="embedStyle"
        loading="lazy"
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
        class="embed-style wsRoundedLight"
        allow="encrypted-media"
        allowfullscreen ></iframe>

    <div v-else>
      <div class="d-flex">
        <v-icon large class="mx-auto">mdi-link-variant-off</v-icon>
      </div>

      <h3 class="text-center" >{{  $t('WrongVideoUrl')   }}</h3>
      <h5 class="text-center" >{{  $t('WrongVideoUrlDescription')   }}</h5>

    </div>




  </v-sheet>

</template>



<script>


import embedContent from "@/components/UI/embedVideo/mixin/embedContent";

export default {

  mixins : [embedContent],
  name : 'wsEmbedVideo',

  props: {

    width : {
      type : String,
      default : ''
    },
    height : {
      type : String,
      default : '400'
    },
    src: {
      type: String,
      default : '',
    },
    backgroundColor : {
      type : String
    },
    light : {
      type : Boolean,
      default : false
    }

  },
  watch : {
    src(value) {
      this.PARSE_EMBED_SRC(value)
    }
  },
  computed: {

    embedStyle() {

      if (this.height !== '100%') {
        return 'width : ' + (this.width ? (this.width + 'px;') : '100% ;' ) +
            'height: ' + this.height + 'px;'
            + 'border :none';
      } else {
        return 'width : ' + (this.width ? (this.width + 'px') : '100% ;' ) +
               'height : 100%; border : none'
      }


    }

  },
  mounted() {
    this.PARSE_EMBED_SRC(this.src)
  }

}

</script>

<style>
.embed-style {

}
</style>